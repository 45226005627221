export function escape(s) {
  return s
    .replace(/&amp;/g, '&')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
}

export const numberNotation = (valore, numeroDecimali) => {
  let valoreSafe = parseFloat(valore) || 0
  let [intPart, decimals] = valoreSafe.toFixed(numeroDecimali).toString().split('.')
  return intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (numeroDecimali > 0 ? ',' + decimals : '')
}

// Lo distinguo da numberNotation, non si sa mai che i prezzi possano avere un formato diverso successivamente (es includere valuta)
export const priceNotation = (valore, numeroDecimali) => {
  return numberNotation(valore, numeroDecimali)
}

export const priceNotationNumber = (valore, numeroDecimali) => {
  const valoreStringa = String(valore).replace(',', '.')
  const valoreNumerico = parseFloat(valoreStringa)
  const valoreSafe = !isNaN(valoreNumerico) ? valoreNumerico : 0
  return parseFloat(valoreSafe.toFixed(numeroDecimali))
}

export const dateIsoToIt = (dateIso) => {
  let dateParts = dateIso ? dateIso.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/) : null
  return dateParts ? dateParts[3] + '/' + dateParts[2] + '/' + dateParts[1] : ''
}

export const handleScrollRef = (ref) => {
  if (ref?.current) {
    window.scrollTo(
      0,
      ref.current.offsetTop -
        (document.getElementsByClassName('header').length > 0
          ? document.getElementsByClassName('header')[0].clientHeight
          : 0) -
        (document.getElementsByClassName('toolbar').length > 0
          ? document.getElementsByClassName('toolbar')[0].clientHeight
          : 0)
    )
  }
}

/**
 * value:	"merce personalizzata con prenotazione di {100} articoli il {2024-11-28}"
 *
 * returns: ["merce personalizzata con prenotazione di {0} articoli il {1}", ["100", "2024-11-28"]]
 */
export const getSafeTranslation = (inputString) => {
  if (!inputString) return ['', []]

  // Regular expression to find curly brace patterns
  const regex = /{([^}]+)}/g

  // Array to hold the values between curly braces
  let values = []
  // Counter for the placeholders
  let placeholderIndex = 0

  // Replace the content inside curly braces with indexed placeholders
  const formattedString = inputString.replace(regex, (match, value, index) => {
    // Push the value inside the curly braces into the values array
    values.push(value)
    // Return the indexed placeholder
    return `{${placeholderIndex++}}`
  })

  // Return the formatted string and the values array in order to be used as input in useTrans
  return [formattedString, values]
}
